import * as NewsFetchCoordinator from 'OK/NewsFetchCoordinator';
import LayerController from 'OK/notifications/LayerController';
import * as ActionsController from 'OK/notifications/ActionsController';
import { ContentData } from 'OK/notifications/ContentData';
import * as logger from 'OK/logger';
import { getLayerElement } from 'OK/notifications/NotificationsHelper';
import type { NotificationCategory } from 'OK/notifications/types';

/**
 * @see one.app.community.dk.blocks.body.notifications.NotificationsControllerBlock
 */
const NOTIFICATIONS_CONTROLLER_BLOCK_SHORT_NAME = 'NTF';

const LOG_OPERATION = 'ntf';

function toolbarButtonClickListener(event: MouseEvent) {
    event.stopPropagation();

    if (LayerController.category()) {
        LayerController.hide();
    } else {
        LayerController.show();
    }
}

export { LayerController };

export function activate(element: HTMLElement) {
    // если открыто по шортлинку, нужно сразу показать
    if (getLayerElement()) {
        logger.success(LOG_OPERATION, 'short-link');
        LayerController.show();
    }

    (element.parentNode as HTMLElement).addEventListener('click', toolbarButtonClickListener);

    ActionsController.init(LayerController);
    NewsFetchCoordinator.addBlock(NOTIFICATIONS_CONTROLLER_BLOCK_SHORT_NAME);
}

export function setNewContent(content: string) {
    try {
        ActionsController.handle(new ContentData(content));
    } catch (error) {
        logger.clob(LOG_OPERATION, content + '\n' + (error as Error).stack, 'fail', 'actions');
    }
}

// используется как activate-хук через data-activate
export function showContent(element: HTMLElement) {
    setTimeout(() => {
        LayerController.showContent(
            element.getAttribute('data-category') as NotificationCategory,
            element.getAttribute('data-history') === 'on'
        );
    });
}